import Popup from "./popup";
import "./joinRoom.css";
import React, { useState } from "react";

function JoinRoom(props) {
    const [room, setRoom] = useState("");

    const handleChange = (text) => {
        text = text.toUpperCase();
        if (text.length > 4) {
            text = text.substr(1, 4);
        }
        if (text.length === 4) {
            props.selectRoom(text);
        }
        setRoom(text);
    };

    return (
        <>
        <Popup>
            <div className="flex-col flex-grow popup-body">
                <div className="popup-title padding-content">Enter The Room Number</div>
                <div className="flex-col flex-center padding-content">
                    <input className="join-room-edit" value={room} onChange={(e) => handleChange(e.target.value)}></input>
                </div>
                <a href="/bin/UYW.exe" className="popup-download padding-content" download="UYW.exe">Download Game v1.1 (2022-02-20)</a>
            </div>
        </Popup>
      </>
    );
}

export default JoinRoom;
