import Popup from "./popup";
import "./reconnectPlayer.css";
import React, { useState } from "react";

function ReconnectPlayer(props) {
    function onReconnect(playerName) {
        props.onReconnect(playerName);
    }

    return (
        <Popup>
            <div className="flex-col flex-grow popup-body">
                <div className="popup-title padding-content">Who Are You ?</div>
                <div className="flex-col flex-center padding-content">
                    {props.room.players.map((element, index) => {
                        return (
                            <button onClick={() => onReconnect(element.name)} className="button min-width">
                                {element.name}
                            </button>
                        );
                    })}
                </div>
            </div>
        </Popup>
    );
}

export default ReconnectPlayer;
