//export const appUrl = "http://localhost:3001/api/";
export const appUrl = window.location.href + "api/";
console.log(appUrl);

export function deepCopy(data) {
    return JSON.parse(JSON.stringify(data));
}

export async function getRequest(url) {
    const res = await fetch(appUrl + url, {
        method: "GET",
        headers: { "Content-Type": "application/json" },
    });
    const result = await res.json();
    return result;
}

export async function postRequest(url, data) {
    const res = await fetch(appUrl + url, {
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify(data),
    });
    const result = await res.json();
    console.log(result);
    return result;
}

export async function deleteRequest(url) {
    const res = await fetch(appUrl + url, {
        method: "DELETE",
        headers: { "Content-Type": "application/json" },
    });
    const result = await res.json();
    console.log(result);
    return result;
}

export async function uploadImage(url, file) {
    const data = new FormData();
    data.append("file", file);
    const res = await fetch(appUrl + url, {
        method: "POST",
        body: data,
    });
    const result = await res.json();
    console.log(result);
    return result;
}
