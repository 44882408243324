import Popup from "./popup";
import "./addPlayer.css";
import React, { useState } from "react";

function AddPlayer(props) {
    const [player, setPlayer] = useState("");

    function handleChange(text) {
        setPlayer(text);
    }

    function onAccept() {
        props.onAccept(player);
    }

    return (
        <Popup>
            <div className="flex-col flex-grow popup-body">
                <div className="popup-title padding-content">Enter Player Name</div>
                <div className="flex-col flex-center padding-content">
                    <input className="popup-edit" value={player} onChange={(e) => handleChange(e.target.value)}></input>
                </div>
                <div className="flex-row flex-right padding-content">
                    <button className={"button min-width " + props.acceptColorClass} onClick={onAccept}>
                        {props.acceptLabel}
                    </button>
                </div>
            </div>
        </Popup>
    );
}

export default AddPlayer;
