import React from "react";
import Popup from "./popup";
import "./wait.css";

function Wait(props) {
    return (
        <>
            {props.message && props.message.length > 0 && (
                <Popup>
                    <div className="flex-col flex-grow popup-body">
                        <div className="popup-title padding-content">Waiting</div>
                        <div className="flex-col flex-center padding-content">
                            <p className="popup-label">{props.message}</p>
                        </div>
                    </div>
                </Popup>
            )}
        </>
    );
}

export default Wait;
