import { useEffect, useState } from "react";
import logo from "./logo.svg";
import "./style.css";
import "./App.css";
import JoinRoom from "./joinRoom";
import AddPlayer from "./addPlayer";
import Wait from "./wait";
import { getRequest, postRequest } from "./utils";
import AnswerForm from "./answerForm";
import Vote from "./vote";
import ReconnectPlayer from "./reconnectPlayer";

function App() {
    const [state, setState] = useState("ConnectToRoom");
    const [message, setMessage] = useState("");
    const [roomName, setRoomName] = useState(null);
    const [room, setRoom] = useState(null);
    const [player, setPlayer] = useState(null);
    const [connected, setConnected] = useState(false);

    useEffect(() => {
        if (connected) {
            const timer = setInterval(async () => {
                const result = await getRequest("getState/?room=" + roomName + "&player=" + player);
                if (result.state !== undefined) {
                    if (result.message) setMessage(result.message);
                    else setMessage("");
                    setState(result.state);
                    console.log(result);
                } else {
                    setMessage("");
                }
            }, 1000);
            return () => {
                if (timer) clearInterval(timer);
            };
        }
    }, [connected]);

    const selectRoom = async (roomName) => {
        const room = await getRequest("getRoom/" + roomName);
        console.log(room);
        if (room.allPlayersConnected !== undefined) {
            setRoom(room)
            if(room.allPlayersConnected === false) {
                setRoomName(roomName);
                setState("Login");
            }
            else {
                setRoomName(roomName);
                setState("Reconnect");
            }
        }
    };

    const onSetPlayer = async (playerName) => {
        const result = await getRequest("addPlayer/?room=" + roomName + "&player=" + playerName);
        if (result.ok !== undefined && result.ok === true) {
            setPlayer(playerName);
            setConnected(true);
        }
    };

    const setAnswer = async (answer) => {
        let data = { room: roomName, player: player, answer: answer };
        await postRequest("setAnswer", data);
    };

    const setVote = async (value) => {
        let data = { room: roomName, player: player, vote: value };
        await postRequest("setVote", data);
    };

    const onReconnect =  async (playerName) => {
        console.log(playerName)
        setPlayer(playerName);
        setConnected(true);
        setState("Wait");
    }

    return (
        <div className="App">
            <header className="App-header">
                {/* <img src={logo} className="App-logo" alt="logo" /> */}
                {/* <Vote answers={answers} room="AAAA" player="toto" onAnswer={onVote} /> */}
            </header>
            <body>
                {state === "ConnectToRoom" && <JoinRoom selectRoom={(name) => selectRoom(name)} />}
                {state === "Login" && <AddPlayer acceptLabel="Ok" onAccept={(name) => onSetPlayer(name)} />}
                {state === "Reconnect" && <ReconnectPlayer room={room} onReconnect={(name) => onReconnect(name)} />}
                {state === "Wait" && player !== null && <Wait message={message} />}
                {state === "Answer" && <AnswerForm onAccept={(answer) => setAnswer(answer)} />}
                {state === "Vote" && <Vote room={roomName} player={player} onVote={(vote) => setVote(vote)} />}
            </body>
        </div>
    );
}

export default App;
