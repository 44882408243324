import React, { useRef } from "react";
import Popup from "./popup";
import "./answerForm.css";

function AnswerForm(props) {
    const textArea = useRef(null);
    const onAccept = () => {
        if (textArea && textArea.current.value.length > 0) props.onAccept(textArea.current.value);
    };

    return (
        <Popup>
            <div className="flex-col flex-grow popup-body">
                <div className="popup-title padding-content">Enter Your Answer</div>
                <div className="flex-col flex-center padding-content">
                    <textarea className="popup-textarea" ref={textArea}></textarea>
                </div>
                <div className="flex-row flex-right padding-content">
                    <button className={"button min-width " + props.acceptColorClass} onClick={onAccept}>
                        Ok
                    </button>
                </div>
            </div>
        </Popup>
    );
}

export default AnswerForm;
