import React, { useState, useEffect } from "react";
import Popup from "./popup";
import "./vote.css";
import { getRequest } from "./utils";

function Vote(props) {
    const [answers, setAnswers] = useState(null);

    useEffect(async () => {
        const result = await getRequest("getAnswers/?room=" + props.room);
        console.log(result);
        setAnswers(result);
    }, []);

    const onVote = (index) => {
        if (answers && answers.answers[index].author !== props.player) {
            props.onVote(index);
        }
    };

    return answers ? (
        <Popup>
            <div className="flex-col flex-grow popup-body">
                <div className="popup-title padding-content">Choose your favorite</div>
                <div className="flex-col flex-center padding-content">
                    {answers.answers.map((element, index) => {
                        return (
                            <>
                            { element.answer.length > 0 && 
                                <button onClick={() => onVote(index)} className={"button min-width " + (element.author === props.player ? "button-gray" : "")}>
                                    {element.answer}
                                </button>
                            }
                            </>
                        );
                    })}
                </div>
            </div>
        </Popup>
    ) : (
        <></>
    );
}

export default Vote;
